import {combineReducers} from 'redux'
import SolarSystem from '../planets/SolarSystem'

const solarSystem = (state = new SolarSystem()) => {
  return state
}

const planetRenderer = (state = null, action) => {
  switch (action.type) {
    case 'INIT_PLANET_RENDERER':
      return action.renderer
    default:
      return state
  }
}

const date = (state = new Date(), action) => {
  let url = ''
  switch (action.type) {
    case 'SET_DATE':
      url = `${window.location.origin}${window.location.pathname}?t=${Math.floor(action.date.getTime() / 1000)}`
      window.history.replaceState({path: url}, '', url)

      return action.date
    default:
      return state
  }
}

const rootReducer = combineReducers({
  solarSystem,
  planetRenderer,
  date
})

export default rootReducer
