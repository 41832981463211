require('material-design-lite/material')
require('./material.min.css')
require('./style.css')

import React from 'react'
import {render} from 'react-dom'
import {Provider, connect} from 'react-redux'
import {createStore} from 'redux'
import {setDate} from './actions'
import Root from './components/root.jsx'
import reducer from './reducers'
const store = createStore(reducer)

function mapStateToProps(state) {
  return state
}

const date = new Date()
if (window.location.search) {
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has('t')) {
    const timestamp = parseInt(searchParams.get('t'), 10)
    if (timestamp) {
      date.setTime(timestamp * 1000)
      store.dispatch(setDate(date))
    }
  }
}

const AppContainer = connect(mapStateToProps)(Root)

render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('app')
)
