import React, {Component} from 'react'

export default class NavigationHeader extends Component {
  render() {
    return (
      <header className="mdl-layout__header">
        <div className="mdl-layout__header-row">
          <span className="mdl-layout-title">KIDOU</span>
          <div className="mdl-layout-spacer" />
          <nav className="mdl-navigation">
            <a className="mdl-navigation__link" href="https://rysk92.github.io/milky_way_orbit_course/" target="_blank" rel="noopener noreferrer"><i className="material-icons">book</i> Document</a>
          </nav>
        </div>
      </header>
    )
  }
}
