import PropTypes from 'prop-types'
import React, {Component} from 'react'
import TrackballControls from 'three-trackballcontrols'
import DataMenuContainer from '../containers/data-menu-container'
import {initPlanetRenderer} from './../actions'
import AstronomyUtils from './../planets/AstronomyUtils'
import FixedStar from './../planets/FixedStar'
import PlanetRenderer from './../planets/PlanetsRenderer'

export default class MainContent extends Component {
  componentDidMount () {
    const target = document.getElementById('render-target')
    let interval = setInterval(() => {
      if (target.clientHeight !== 0) {
        this.planetRenderer = new PlanetRenderer(
          target,
          this.props.solarSystem,
          {
            width: target.clientWidth,
            height: target.clientHeight,
            zoom: 30
          }
        )
        const controls = new TrackballControls(this.planetRenderer.camera, this.planetRenderer.renderer.domElement)
        controls.minDistance = 50
        controls.maxDistance = 3000
        this.planetRenderer.setControl(controls)

        this.planetRenderer.on('change', () => {
          this.setState({planetRenderer: this.planetRenderer})
        })

        this.setState({planetRenderer: this.planetRenderer})

        this.planetRenderer.addFixedStar(
          'Glise581',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(15, 19, 26.823),
              declination: AstronomyUtils.degToRad(-7, 43, 20)
            },
            20.24
          )
        )

        // しし座 α
        this.planetRenderer.addFixedStar(
          'α Leo - Regulus',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(10, 8, 22.31099),
              declination: AstronomyUtils.degToRad(11, 58, 1.9516)
            },
            79.26
          )
        )
        // しし座 β
        this.planetRenderer.addFixedStar(
          'β Leo - Denebola',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(11, 49, 3.57834),
              declination: AstronomyUtils.degToRad(14, 34, 19.409)
            },
            35.86
          )
        )
        // しし座 γ
        this.planetRenderer.addFixedStar(
          'γ Leo - Algieba',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(10, 19, 58.35056),
              declination: AstronomyUtils.degToRad(19, 50, 29.3468)
            },
            130.04
          )
        )
        // しし座 δ
        this.planetRenderer.addFixedStar(
          'δ Leo - Zosma',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(11, 14, 6.50142),
              declination: AstronomyUtils.degToRad(20, 31, 25.3853)
            },
            58.4
          )
        )
        // しし座 ζ
        this.planetRenderer.addFixedStar(
          'ζ Leo - Adhafera',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(10, 16, 41.41597),
              declination: AstronomyUtils.degToRad(23, 25, 2.3221)
            },
            273.95
          )
        )
        // しし座 θ
        this.planetRenderer.addFixedStar(
          'θ Leo - Chertan',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(11, 14, 14.40446),
              declination: AstronomyUtils.degToRad(15, 25, 46.4541)
            },
            164.98
          )
        )
        // しし座 μ
        this.planetRenderer.addFixedStar(
          'μ Leo - Rasalas',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(9, 52, 45.81654),
              declination: AstronomyUtils.degToRad(26, 0, 25.0319)
            },
            124.05
          )
        )
        // しし座 η
        this.planetRenderer.addFixedStar(
          'η Leo - Al Jabhah',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(10, 7, 19.95),
              declination: AstronomyUtils.degToRad(16, 45, 45.6)
            },
            1268
          )
        )
        // しし座 ε
        this.planetRenderer.addFixedStar(
          'ε Leo - Ras Elased',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(9, 45, 51.1),
              declination: AstronomyUtils.degToRad(23, 46, 27.3)
            },
            247
          )
        )

        // 北極星 こぐま座 α
        this.planetRenderer.addFixedStar(
          'Polaris',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(2, 31, 49.09456),
              declination: AstronomyUtils.degToRad(89, 15, 50.7923)
            },
            432.36
          )
        )

        // てんびん座 α
        this.planetRenderer.addFixedStar(
          'α Librae',
          new FixedStar(
            {
              rightAscension: AstronomyUtils.hourToRad(14, 50, 41.18097),
              declination: AstronomyUtils.degToRad(-15, 59, 50.0482)
            },
            74.91
          )
        )
        this.planetRenderer.animate()
        clearInterval(interval)

        // for debug
        window.r = this.planetRenderer
        this.props.dispatch(initPlanetRenderer(this.planetRenderer))
        this.planetRenderer.setDate(this.props.date)

        window.addEventListener(
          'resize',
          () => {
            this.planetRenderer.resize(target.clientWidth, target.clientHeight)
          },
          false
        )
      }
    }, 100)
  }

  render () {
    return (
      <main className='mdl-layout__content'>
        <div id='render-target'></div>
        <DataMenuContainer />
      </main>
    )
  }
}

MainContent.propTypes = {
  solarSystem: PropTypes.object.isRequired
}
